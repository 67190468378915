const UserMainterTypes = {
  FILTER_USERS: '[UserMaintainer] FILTER_USERS',

  FETCH_USERS: '[UserMaintainer] FETCH_USERS',
  FETCH_USERS_SUCCESS: '[UserMaintainer] FETCH_USERS_SUCCESS',
  FETCH_USERS_FAIL: '[UserMaintainer] FETCH_USERS_FAIL',

  OPEN_USER_MODAL: '[UserMaintainer] OPEN_USER_MODAL',
  CLOSE_USER_MODAL: '[UserMaintainer] CLOSE_USER_MODAL',

  EDIT_VERIFY_USER: '[UserMaintainer] EDIT_VERIFY_USER',
  EDIT_USER_MODAL: '[UserMaintainer] EDIT_USER_MODAL',

  UPDATE_USER: '[UserMaintainer] UPDATE_USER',
  UPDATE_USER_SUCCESS: '[UserMaintainer] UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL: '[UserMaintainer] UPDATE_USER_FAIL',

  DELETE_USER: '[UserMaintainer] DELETE_USER',
  DELETE_USER_SUCCESS: '[UserMaintainer] DELETE_USER_SUCCESS',
  DELETE_USER_FAIL: '[UserMaintainer] DELETE_USER_FAIL',

  FILTER_USER_TABLE: '[UserMaintainer] FILTER_USER_TABLE',
  CLEAR_FILTER_USER_TABLE: '[UserMaintainer] CLEAR_FILTER_USER_TABLE',

  CLOSE_DELETE_CONFIRM_MODAL: '[UserMaintainer] CLOSE_DELETE_CONFIRM_MODAL',
  OPEN_DELETE_CONFIRM_MODAL: '[UserMaintainer] OPEN_DELETE_CONFIRM_MODAL',
};

export default UserMainterTypes;
