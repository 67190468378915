import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import sectionStyles from './section.styles';

function WelcomeSection() {
  return (
    <Container sx={sectionStyles.container}>
      <Grid container paddingTop={10} spacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            ¡Bienvenido al sitio oficial de la Conciliación Car Carrier!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="justify">
            La Asociación Regional de Consumidores Adultos Mayores Región del Bío-Bío alcanzó un
            acuerdo de conciliación parcial en un juicio colectivo tramitado ante el Tribunal de
            Defensa de la Libre Competencia (rol CIP-6-2020). Este sitio web ha sido creado y es
            administrado por Deloitte Asesorías Legales y Tributarias Limitada, entidad nominada por
            las partes como administrador de la conciliación en calidad de tercero independiente y
            cuyo nombramiento fue aprobado por el TDLC, según consta en la resolución de 9 de mayo
            de 2024.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={sectionStyles.subtitle}>
            <WarningRoundedIcon sx={{ paddingRight: 1 }} />
            Este portal web es el único medio de recepción y procesamiento de las solicitudes de
            beneficio individual.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default WelcomeSection;
