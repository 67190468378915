/* Material UI */
import HomeIcon from '@mui/icons-material/Home';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import ListAltIcon from '@mui/icons-material/ListAlt';
// import QuizIcon from '@mui/icons-material/Quiz';
// import LooksOneIcon from '@mui/icons-material/LooksOne';
// import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
// import { Group } from '@mui/icons-material';

/* Router */
// import Counter from 'content/pages/Counter/Counter';
import Home from 'content/pages/Home/Home';
// import Login from 'content/pages/Login/Login';
// import RegisterUser from 'content/pages/Register/Register';
// import RecoveryPassword from 'content/pages/Recovery/RecoveryPassword';
// import UserTasks from 'content/pages/UserTasks/UserTasks';
// import PhotoLibrary from 'content/pages/PhotoLibrary/PhotoLibrary';
// import UserMaintainer from 'content/pages/UserMaintainer/UserMaintainer';
// import Profile from 'content/pages/Profile/Profile';

// import { ALL as allRoles, ADMIN as adminRole, USER as useRole } from './roles';
import { ALL as allRoles } from './roles';

const Paths = {
  publics: [
    {
      path: '/',
      name: 'Inicio',
      component: Home,
      useLayout: true,
      Icon: HomeIcon,
      allowedRoles: [...allRoles],
    },
    // {
    //   path: '/recovery/*',
    //   component: RecoveryPassword,
    //   useLayout: false,
    //   Icon: null,
    //   isAuth: true,
    //   allowedRoles: [...allRoles],
    // },
    // {
    //   path: '/login',
    //   name: 'Login',
    //   component: Login,
    //   useLayout: false,
    //   Icon: AccountCircleIcon,
    //   isAuth: true,
    //   allowedRoles: [...allRoles],
    // },
    // {
    //   path: '/register',
    //   component: RegisterUser,
    //   useLayout: false,
    //   Icon: null,
    //   isAuth: true,
    //   allowedRoles: [...allRoles],
    // },
    // {
    //   path: '/counter',
    //   name: 'Contador',
    //   component: Counter,
    //   useLayout: true,
    //   Icon: LooksOneIcon,
    //   allowedRoles: [adminRole, useRole],
    // },
    // {
    //   path: '/photo-library',
    //   name: 'Librería de fotos',
    //   component: PhotoLibrary,
    //   useLayout: true,
    //   Icon: PhotoLibraryIcon,
    //   allowedRoles: [adminRole, useRole],
    // },
  ],
  shared: [],
  protecteds: [
    // {
    //   path: '/todo',
    //   name: 'Tareas',
    //   component: UserTasks,
    //   Icon: ListAltIcon,
    //   allowedRoles: [adminRole, useRole],
    // },
    // {
    //   path: '/profile',
    //   name: 'Perfil',
    //   component: Profile,
    //   Icon: AccountCircleIcon,
    //   allowedRoles: [adminRole, useRole],
    // },
    // {
    //   path: '/user-maintainer',
    //   name: 'Mantenedor de usuarios',
    //   component: UserMaintainer,
    //   Icon: Group,
    //   allowedRoles: [adminRole],
    // },
  ],
  independients: [
    // {
    //   path: '/faq',
    //   name: 'FAQ',
    //   component: Counter,
    //   Icon: QuizIcon,
    //   allowedRoles: [adminRole, useRole],
    // },
  ],
};

export default Paths;
