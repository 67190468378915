import React from 'react';

// Material
import Grid from '@mui/material/Grid';
import { IconButton, Typography, Link, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

/* Project */
const deloitteLogo = `${window.location.origin}/images/deloitte-logo.svg`;

function Footer() {
  const navigate = useNavigate();

  return (
    <div
      className="footer"
      style={{ minHeight: '25vh', backgroundColor: 'black', color: 'white', paddingTop: '35px' }}>
      <Container>
        <Grid container justifyContent="space-between">
          <Grid item display="flex" direction="column">
            <IconButton onClick={() => navigate('/')} sx={{ paddingLeft: 0, marginBottom: '10px' }}>
              <img style={{ height: '40px' }} src={deloitteLogo} alt="" />
            </IconButton>
            <Link
              href="/documents/06%20Pol%C3%ADtica%20de%20Protecci%C3%B3n%20de%20Datos%20Conciliaci%C3%B3n%20Carrier.pdf"
              download="protección-datos-personales.pdf">
              Declaración de Privacidad
            </Link>
          </Grid>
          <Grid item>
            <Typography gutterBottom>Para más información:</Typography>
            <Typography gutterBottom>
              <PhoneIcon sx={{ paddingRight: 1 }} />
              227297000
            </Typography>
            <Typography gutterBottom display="flex" alignItems="center">
              <EmailIcon sx={{ paddingRight: 1 }} />
              conciliacioncarrier@deloitte.cl
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Footer;
