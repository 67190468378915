import { createAction } from '@reduxjs/toolkit';

import UserMaintainerTypes from './userMaintainer.types';

export const fetchUserList = createAction(UserMaintainerTypes.FETCH_USERS);

export const fetchUserListFail = createAction(UserMaintainerTypes.FETCH_USERS_FAIL);

export const fetchUserListSuccess = createAction(UserMaintainerTypes.FETCH_USERS_SUCCESS);

export const openUserModal = createAction(UserMaintainerTypes.OPEN_USER_MODAL);

export const closeUserModal = createAction(UserMaintainerTypes.CLOSE_USER_MODAL);

export const filterUserTable = createAction(UserMaintainerTypes.FILTER_USER_TABLE);

export const clearFilterUserTable = createAction(UserMaintainerTypes.CLEAR_FILTER_USER_TABLE);

export const verifyUser = createAction(UserMaintainerTypes.EDIT_VERIFY_USER);

export const updateUser = createAction(UserMaintainerTypes.UPDATE_USER);

export const updateUserFail = createAction(UserMaintainerTypes.UPDATE_USER_FAIL);

export const deleteUserFail = createAction(UserMaintainerTypes.DELETE_USER_FAIL);

export const deleteUserSuccess = createAction(UserMaintainerTypes.DELETE_USER_SUCCESS);

export const openDeleteConfirmModal = createAction(UserMaintainerTypes.OPEN_DELETE_CONFIRM_MODAL);

export const closeDeleteConfirmModal = createAction(UserMaintainerTypes.CLOSE_DELETE_CONFIRM_MODAL);

export const updateUserSuccess = (payload) => (dispatch) => {
  dispatch({
    type: UserMaintainerTypes.UPDATE_USER_SUCCESS,
    payload,
  });

  dispatch({
    type: UserMaintainerTypes.CLOSE_USER_MODAL,
  });

  dispatch({
    type: UserMaintainerTypes.FILTER_USER_TABLE,
    payload: {},
  });
};

export const editUser = (payload) => (dispatch) => {
  dispatch({
    type: UserMaintainerTypes.EDIT_USER_MODAL,
    payload: {
      ...payload,
    },
  });

  dispatch({
    type: UserMaintainerTypes.EDIT_VERIFY_USER,
  });
};

export const deleteUser = createAction(UserMaintainerTypes.DELETE_USER);

const UserMaintainerActions = {
  fetchUserList,
  fetchUserListFail,
  fetchUserListSuccess,
  openUserModal,
  closeUserModal,
  editUser,
  filterUserTable,
  verifyUser,
  updateUser,
  updateUserFail,
  updateUserSuccess,
  deleteUser,
  deleteUserFail,
  deleteUserSuccess,
  openDeleteConfirmModal,
  closeDeleteConfirmModal,
};

export default UserMaintainerActions;
