import { createReducer } from '@reduxjs/toolkit';

import {
  sendEmail,
  sendEmailFail,
  sendEmailSuccess,
  setControl,
  validateRecaptcha,
  validateRecaptchaFail,
  validateRecaptchaSuccess,
} from './contact.actions';

const initialState = {
  controls: {
    name: '',
    email: '',
    phone: '',
    message: '',
  },
  isValid: false,
  loading: false,
};

const ContactReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(sendEmail, (state) => {
      state.loading = true;
    })
    .addCase(sendEmailSuccess, (state) => {
      state.loading = false;
      state.controls = { ...initialState.controls };
      state.isValid = false;
    })
    .addCase(sendEmailFail, (state) => {
      state.loading = false;
    })
    .addCase(validateRecaptcha, (state) => {
      state.isValid = false;
    })
    .addCase(validateRecaptchaSuccess, (state, action) => {
      state.isValid = action.payload;
    })
    .addCase(validateRecaptchaFail, (state) => {
      state.isValid = false;
    })
    .addCase(setControl, (state, { payload }) => {
      state.controls = { ...state.controls, ...payload };
    });
});

export default ContactReducer;
