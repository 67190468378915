import { toast } from 'react-toastify';

const defaultInfoToast = ({ message }) =>
  toast.info(message, {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export default defaultInfoToast;
