import React from 'react';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  Container,
  Drawer,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Project
import navbarStyles from './navbar.styles';

const deloitteDesktop = `${window.location.origin}/images/deloitte-desktop.png`;
const deloitteMobile = `${window.location.origin}/images/deloitte-mobile.png`;
function NavBar() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLink = (url) => {
    navigate(url);
    setMobileOpen(false);
  };

  const navItems = [
    { id: 1, url: '/', name: 'Inicio' },
    { id: 2, url: '/#nextDates', name: 'Próximas Fechas' },
    { id: 3, url: '/#documents', name: 'Documentos' },
    { id: 4, url: '/#faq', name: 'Preguntas frecuentes' },
  ];

  const buttons = (
    <>
      {navItems.map((item) => (
        <Button
          key={item.id}
          onClick={() => handleLink(item.url)}
          color="inherit"
          sx={{ textTransform: 'none', fontWeight: 'bold' }}>
          {item.name}
        </Button>
      ))}
    </>
  );

  return (
    <nav>
      <AppBar position="fixed" sx={navbarStyles.navBar}>
        <Container sx={{ px: { sm: 1 } }}>
          <Toolbar sx={{ px: { sm: 0 } }}>
            <IconButton onClick={() => navigate('/')}>
              <img
                style={{ height: '40px' }}
                src={isMobile ? deloitteMobile : deloitteDesktop}
                alt=""
              />
            </IconButton>
            <Typography variant="h6" sx={navbarStyles.title} />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>{buttons}</Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '50%' },
        }}>
        {buttons}
      </Drawer>
    </nav>
  );
}

export default NavBar;
