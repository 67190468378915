const BASE = '[CONTACT]';

const ContactTypes = {
  SET_CONTROL: `${BASE} SET_CONTROL`,
  SEND_EMAIL: `${BASE} SEND_EMAIL`,
  SEND_EMAIL_SUCCESS: `${BASE} SEND_EMAIL_SUCCESS`,
  SEND_EMAIL_FAIL: `${BASE} SEND_EMAIL_FAIL`,
  VALIDATE_RECAPTCHA: `${BASE} VALIDATE_RECAPTCHA`,
  VALIDATE_RECAPTCHA_SUCCESS: `${BASE} VALIDATE_RECAPTCHA_SUCCESS`,
  VALIDATE_RECAPTCHA_FAIL: `${BASE} VALIDATE_RECAPTCHA_FAIL`,
};

export default ContactTypes;
