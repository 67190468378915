import React, { useEffect, useRef } from 'react';

import WelcomeSection from 'content/features/home/components/WelcomeSection';
import InformativeSection from 'content/features/home/components/InformativeSection';
import DocumentSection from 'content/features/home/components/DocumentSection';
import NextDateSection from 'content/features/home/components/NextDateSection';
import FaqSection from 'content/features/home/components/FaqSection';

import { useLocation } from 'react-router-dom';
import PrivacyStatementSection from 'content/features/home/components/PrivacyStatementSection';
import TermAndConditions from 'content/features/home/components/TermAndConditions';

function Home() {
  // const navigate = useNavigate();
  const sectionRefs = {
    welcome: useRef(null),
    nextDates: useRef(null),
    documents: useRef(null),
    faq: useRef(null),
    requirements: useRef(null),
    privacyStatement: useRef(null),
  };

  const location = useLocation();
  // eslint-disable-next-line complexity
  useEffect(() => {
    console.log(location.hash);
    const key = location.hash.replace('#', '');
    console.log({ key });
    if (key && sectionRefs[key] && sectionRefs[key].current) {
      sectionRefs[key].current.scrollIntoView({ behavior: 'smooth' });
    } else {
      sectionRefs.welcome.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  // const handleScroll = () => {
  //   const scrollPosition = window.scrollY;
  //   let currentSection = 'welcome';
  //   // eslint-disable-next-line complexity
  //   Object.keys(sectionRefs).forEach((key) => {
  //     const ref = sectionRefs[key];
  //     if (ref.current) {
  //       const { offsetTop, offsetHeight } = ref.current;
  //       if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
  //         currentSection = key;
  //       }
  //     }
  //   });

  //   navigate(`#${currentSection}`, { replace: true });
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <div style={{ background: '#f7f7f7' }} ref={sectionRefs.welcome}>
      <div style={{ background: '#000', color: '#fff' }}>
        <WelcomeSection />
      </div>
      <InformativeSection ref={sectionRefs.requirements} />
      <NextDateSection ref={sectionRefs.nextDates} />
      <div style={{ background: '#fff' }}>
        <DocumentSection ref={sectionRefs.documents} />
      </div>
      <FaqSection ref={sectionRefs.faq} />
      <div style={{ background: '#fff' }}>
        <PrivacyStatementSection ref={sectionRefs.privacyStatement} />
      </div>
      <TermAndConditions ref={sectionRefs.privacyStatement} />
    </div>
  );
}

export default Home;
