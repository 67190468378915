import React, { forwardRef } from 'react';

// Material
import Grid from '@mui/material/Grid';
import { Typography, Container, Link } from '@mui/material';
import { downloadFile } from 'utils/functions';
import sectionStyles from './section.styles';

const TermAndConditions = forwardRef((props, ref) => (
  <Container sx={sectionStyles.container} ref={ref}>
    <Grid container spacing={{ sm: 8, xs: 4 }}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={sectionStyles.title}>
          Términos y condiciones de uso del sitio
        </Typography>
        <Typography sx={sectionStyles.subtitle}>1. Sitio web</Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          Estos términos y condiciones aplican al sitio que usted está visitando,
          conciliacioncarcarrier.cl.
        </Typography>
        <Typography sx={sectionStyles.subtitle}>2. Uso del contenido</Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          Usted está autorizado únicamente a ver y participar en el proceso de compensación de este
          sitio web, siempre y cuando cumpla con las siguientes condiciones: <br />- El uso del
          contenido es únicamente para fines legales definidos en el acuerdo de conciliación. No
          está autorizado a copiar o utilizar cualquier software, proceso patentado o tecnología
          incorporada o descrita en este sitio web.
        </Typography>
        <Typography sx={sectionStyles.subtitle}>3. Cumplimiento de las leyes</Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          Usted debe cumplir con todas las leyes aplicables respecto al acceso y uso de este sitio
          web.
        </Typography>
        <Typography sx={sectionStyles.subtitle}>4. Privacidad y cookies</Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          Al utilizar este sitio web, usted acepta que podemos utilizar cookies propias, de sesión y
          técnicas para cumplir con finalidades señaladas en esta política de privacidad.
        </Typography>
        <Typography sx={sectionStyles.subtitle}>5. Derechos de propiedad intelectual</Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          El contenido de este sitio web está protegido por derechos de autor, derechos de propiedad
          industrial, marcas registradas y otras leyes de Chile. Deloitte es propietario del sitio
          web. No está autorizado utilizar ningún tipo de información almacenada en este sitio web.
        </Typography>
        <Typography sx={sectionStyles.subtitle}>6. Limitación de responsabilidad</Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          Este sitio web y su contenido se proporcionan &ldquo;tal cual&rdquo; y no otorgamos
          ninguna garantía expresa o implícita sobre su seguridad, precisión o desempeño. No seremos
          responsables por cualquier daño directo, indirecto, incidental, consecuente o punitivo
          derivado del uso de este sitio web.
        </Typography>
        <Typography sx={sectionStyles.subtitle}>7. Enlaces a terceros</Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          Este sitio web puede contener enlaces a sitios web de terceros sobre los cuales no tenemos
          control. No garantizamos la precisión o el contenido de estos sitios web y no somos
          responsables de ningún daño derivado de su uso.
        </Typography>
        <Typography sx={sectionStyles.subtitle}>8. Modificaciones</Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento.
          Cualquier modificación será efectiva después de su publicación en este sitio web. Es su
          responsabilidad revisar periódicamente estos términos y condiciones para estar al tanto de
          cualquier cambio.
        </Typography>
        <Typography sx={sectionStyles.subtitle}>9. Jurisdicción</Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          Estos términos y condiciones se rigen por las leyes de Chile. Cualquier disputa
          relacionada con este sitio web se someterá a la jurisdicción exclusiva de los tribunales
          chilenos. Si tiene alguna pregunta o inquietud sobre estos términos y condiciones,
          contáctenos a través de la información de contacto proporcionada en este sitio web.
        </Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          Si desea ver más información sobre los términos y usos del sitio, haga click{' '}
          <Link
            href="/#"
            onClick={(event) =>
              downloadFile(event, '07 Términos y Condiciones de Uso Conciliación Carrier.pdf')
            }>
            aquí
          </Link>
          .
        </Typography>
      </Grid>
    </Grid>
  </Container>
));

export default TermAndConditions;
