import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import sectionStyles from './section.styles';

// const formula = `${window.location.origin}/images/formula.png`;

const InformativeSection = forwardRef((props, ref) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  return (
    <Container sx={sectionStyles.container}>
      <Grid container spacing={{ sm: 8, xs: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={sectionStyles.title}>
            La Conciliación
          </Typography>
          <Typography variant="body1" gutterBottom align="justify">
            Gracias a la Conciliación, los consumidores que hayan comprado determinados vehículos
            nuevos entre los años 2000 y 2015 (los “Vehículos Elegibles”) y cumplan ciertos
            requisitos adicionales (los “Beneficiarios”) podrán solicitar el pago de un beneficio
            individual en dinero que puede llegar hasta un máximo de 0,5 UF.
          </Typography>
          <Typography variant="body1" gutterBottom align="justify">
            La Conciliación contempla un monto total de 39.000 UF para distribuir entre los
            Beneficiarios. Si no existieren suficientes solicitudes aprobadas como para distribuir
            el total, considerando el máximo de 0,5 UF, el saldo se entregará en partes iguales a
            las siguientes fundaciones:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Link href="https://www.teleton.cl/" sx={{ textDecoration: 'none', fontWeight: 500 }}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={sectionStyles.subtitle}>
                      Fundación Teletón
                    </Typography>
                    <Typography variant="body2">
                      Teletón es una fundación sin fines de lucro dedicada a la rehabilitación
                      integral de niños, niñas y jóvenes con discapacidad motora, al mejoramiento de
                      su calidad de vida y al desarrollo de sus capacidades para lograr su inclusión
                      social.
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} md={6}>
              <Link href="https://cl.techo.org/" sx={{ textDecoration: 'none', fontWeight: 500 }}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={sectionStyles.subtitle}>
                      Fundación Un Techo para Chile
                    </Typography>
                    <Typography variant="body2">
                      TECHO-Chile es una fundación sin fines de lucro dedicada al servicio de las
                      familias que viven en situación de precariedad habitacional.
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} md={6}>
              <Link
                href="https://desafiolevantemoschile.org/"
                sx={{ textDecoration: 'none', fontWeight: 500 }}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={sectionStyles.subtitle}>
                      Fundación Desafío Levantemos Chile
                    </Typography>
                    <Typography variant="body2">
                      Levantemos Chile es una fundación sin fines de lucro, dedicada a reunir
                      recursos públicos o privados para asistir a las comunidades del país.
                      Actualmente, se enfoca en levantar a las familias damnificadas de la Región de
                      Valparaíso.
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} md={6}>
              <Link
                href="https://fundacionlasrosas.cl/"
                sx={{ textDecoration: 'none', fontWeight: 500 }}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={sectionStyles.subtitle}>
                      Fundación Las Rosas
                    </Typography>
                    <Typography variant="body2">
                      Las Rosas es una fundación sin fines de lucro, dedicada a acoger a las
                      personas mayores más pobres del país. Actualmente, es el establecimiento de
                      larga estadía para adultos mayores más grande del país.
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            component="section"
            sx={{ p: 2, border: '1px solid grey' }}
            alignItems="center"
            display="flex"
            flexDirection="column">
            <Typography variant="body1" paddingBottom={1}>
              Todavía no empieza la implementación de la Conciliación. Para más información,
              diríjase a:
            </Typography>
            <Button
              variant="contained"
              sx={{ textTransform: 'none' }}
              fullWidth={isMobile}
              onClick={() => navigate('/#nextDates')}>
              Próximas fechas
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} ref={ref}>
          <Typography variant="h5" gutterBottom sx={sectionStyles.title}>
            Requisitos para ser Beneficiario
          </Typography>
          <Typography variant="body1" gutterBottom align="justify">
            Según la Conciliación, los Beneficiarios son aquellas personas naturales que acrediten
            haber sido las primeras propietarias de un Vehículo Elegible.
            <br />
            <br />
            Esta definición tiene tres requisitos:
          </Typography>
          <Grid container direction="row" flexWrap="wrap">
            <Grid item mt={2}>
              <Typography
                variant="h6"
                component="div"
                display="flex"
                flexDirection="row"
                sx={sectionStyles.subtitle}>
                <ArrowRightIcon /> Persona natural
              </Typography>
              <Typography variant="body1" mt={1}>
                Individuos de la especie humana, no personas jurídicas.
              </Typography>
            </Grid>
            <Grid item mt={2}>
              <Typography
                variant="h6"
                component="div"
                display="flex"
                flexDirection="row"
                sx={sectionStyles.subtitle}>
                <ArrowRightIcon />
                Primera propietaria
              </Typography>
              <Typography variant="body1" mt={1}>
                Deberá demostrarse a través del certificado de inscripción y anotaciones vigentes
                del vehículo emitido por el Registro de Vehículos Motorizados del Registro Civil.
                <br />
                Sobre la base de ese certificado, se consideran primeras propietarias: (i) quienes
                aparezcan en la primera anotación de dominio, o (ii) quienes aparezcan en la segunda
                anotación de dominio, siempre y cuando la primera anotación corresponda a una
                importadora, distribuidora o comercializadora de vehículos motorizados.
              </Typography>
            </Grid>
            <Grid item mt={2}>
              <Typography
                variant="h6"
                component="div"
                display="flex"
                flexDirection="row"
                sx={sectionStyles.subtitle}>
                <ArrowRightIcon />
                Vehículos Elegibles
              </Typography>
              <Typography variant="body1" mt={1}>
                Corresponde a un listado de determinadas marcas, años y modelos de vehículos
                importados a Chile entre 2000 y 2015. Para revisar el listado y verificar si su
                modelo de automóvil está incluido, pinche aquí:{' '}
                <Link
                  href="/documents/05%20Listado%20de%20veh%C3%ADculos%20elegibles.pdf"
                  target="_blank">
                  Listado de vehículos elegibles
                </Link>
                .
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={sectionStyles.title}>
            Derechos de los Beneficiarios
          </Typography>
          <Typography variant="body1" gutterBottom align="justify">
            Las personas que califiquen como Beneficiarios tienen derecho a solicitar su beneficio
            individual a través de este sitio web,{' '}
            <b>que estará disponible a contar de la Fecha de Implementación</b>. Para más
            información respecto de la fecha de implementación, por favor visite{' '}
            <Link href="/#nextDates">Próximas Fechas</Link>. <br />
            El beneficio individual es una suma de dinero variable depende de la cantidad de
            Beneficiarios que presenten solicitudes, según la siguiente fórmula: 39.000UF/Nº de
            beneficiarios
            <br />
            <br />
            <br />
            En todo caso, el beneficio individual en ningún caso excederá de 0,5 UF por vehículo.{' '}
            <br />
            El beneficio individual se pagará a través de transferencia electrónica o vale vista. Al
            momento de elegir un modo de pago, debe considerarse que: <br />
            (a) Si opta por transferencia electrónica, el Beneficiario deberá indicar en su
            solicitud una cuenta bancaria chilena a su nombre. <br />
            (b) Si opta por vale vista, la persona que lo cobre deberá presentar ante el banco: (i)
            su carnet de identidad, y (ii) si es un apoderado, una copia auténtica del documento que
            acredite su poder. <br />
            Los beneficios individuales se pagarán, como máximo, en la Fecha Límite de Pago, según
            el calendario disponible aquí: <Link href="/#nextDates">Próximas fechas</Link>.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={sectionStyles.title}>
            Procedimiento para solicitar el beneficio individual
          </Typography>
          <Typography variant="body1" gutterBottom align="justify">
            Si usted desea solicitar el pago de un beneficio individual, siga los siguientes pasos:
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h5" component="div" color="primary" fontWeight="500">
                    Paso 1
                  </Typography>
                  <Typography variant="body2">
                    Verifique que usted sea un potencial Beneficiario. Para eso, lea los{' '}
                    <Link href="/#requirements">requisitos de más arriba</Link> asegúrese de que el
                    vehículo nuevo que usted compró entre 2000 y 2015 se encuentre en este listado:{' '}
                    <Link
                      download="listado-vehículos-elegibles.pdf"
                      href="/documents/05%20Listado%20de%20veh%C3%ADculos%20elegibles.pdf">
                      Listado de vehículos elegibles
                    </Link>
                    .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h5" component="div" color="primary" fontWeight="500">
                    Paso 2
                  </Typography>
                  <Typography variant="body2">
                    Obtenga la documentación necesaria. Para eso: Diríjase al sitio web del{' '}
                    <Link href="https://www.registrocivil.cl/principal/servicios-en-linea">
                      Registro Civil
                    </Link>
                    , seleccione “Certificado de Vehículos de anotaciones Vigentes”, introduzca la
                    placa patente de su vehículo y descargue el certificado. Escanee o fotografíe su
                    cédula de identidad por ambos lados.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h5" component="div" color="primary" fontWeight="500">
                    Paso 3
                  </Typography>
                  <Typography variant="body2">
                    Presente su solicitud rellenando y enviando el formulario web que estará
                    disponible en esta página a contar de la Fecha de Implementación (por
                    determinar).
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
});

export default InformativeSection;
