import React from 'react';

/* Material UI */
import { Footer, NavBar } from '../../shared';

function Layout(props) {
  const { children, element } = props;

  return (
    <>
      <NavBar />
      {element || children}
      <Footer />
    </>
  );
}

export default Layout;
