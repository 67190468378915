import { spawn, takeLatest, put } from 'redux-saga/effects';

import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import {
  updateUser,
  updateUserFail,
  updateUserSuccess,
  fetchUserList,
  fetchUserListFail,
  fetchUserListSuccess,
  deleteUser,
  deleteUserFail,
  deleteUserSuccess,
  closeDeleteConfirmModal,
} from './userMaintainer.actions';

function* list() {
  yield takeLatest(fetchUserList, function* fetchUserListSaga() {
    const response = yield apiRequest('api/v1/users', { method: 'get' });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(fetchUserListFail());
    } else {
      yield put(fetchUserListSuccess(response));
    }
  });
}

function* update() {
  yield takeLatest(updateUser, function* updateUserSaga(action) {
    const { name, password, type } = action.payload;

    const body = JSON.stringify({ name, password, type });

    const response = yield apiRequest(`api/v1/users/${action.payload.id}`, { method: 'put', body });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(updateUserFail());
    } else {
      defaultSuccessToast({ message: 'Usuario actualizado exitosamente.' });
      yield put(updateUserSuccess(response));
    }
  });
}

function* remove() {
  yield takeLatest(deleteUser, function* deleteUserSaga(action) {
    const response = yield apiRequest(`api/v1/users/${action.payload.id}`, { method: 'delete' });

    if (response.error) {
      defaultErrorToast({ message: 'El Usuario no pudo ser eliminado.' });
      yield put(deleteUserFail());
    } else {
      defaultSuccessToast({ message: 'Usuario eliminado exitosamente.' });
      yield put(deleteUserSuccess(response));
      yield put(closeDeleteConfirmModal());
    }
  });
}

export default function* UserMaintainerSaga() {
  yield spawn(update);
  yield spawn(list);
  yield spawn(remove);
}
