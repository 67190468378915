import { createReducer } from '@reduxjs/toolkit';

import { validatePassword, truthty } from 'utils/functions';

import { rolesToSelect } from 'utils/roles';

import UserMaintainerTypes from './userMaintainer.types';

const initialUser = {
  id: '',
  name: '',
  type: '',
  password: '',
  passwordConfirmation: '',
};

const initialFilters = {
  name: '',
  email: '',
};

const initialState = {
  loaders: {
    initial: false,
    update: false,
    delete: false,
  },
  controls: {
    userToDelete: null,
    modal: false,
    deleteConfirmModal: false,
    avaibleToSave: false,
  },
  filters: {
    ...initialFilters,
  },
  userErrors: { ...initialUser },
  user: { ...initialUser },
  users: [],
  filtered: [],
  roles: [...rolesToSelect],
};

const UserMaintainerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(UserMaintainerTypes.FETCH_USERS_FAIL, (state) => {
      state.loaders.initial = false;
    })
    .addCase(UserMaintainerTypes.FETCH_USERS_SUCCESS, (state, action) => {
      const formated = Object.values(action.payload)
        .filter(truthty)
        .sort((a, b) => a.name > b.name);

      state.loaders.initial = false;
      state.users = formated;
      state.filtered = formated;
    })
    .addCase(UserMaintainerTypes.OPEN_USER_MODAL, (state, action) => {
      state.controls.modal = true;
      state.user = {
        ...state.user,
        ...action.payload,
      };
    })
    .addCase(UserMaintainerTypes.CLOSE_USER_MODAL, (state) => {
      state.controls.modal = false;
      state.controls.avaibleToSave = false;
      state.user = {
        ...initialState.user,
      };
      state.userErrors = { ...initialUser };
    })
    .addCase(UserMaintainerTypes.CLEAR_FILTER_USER_TABLE, (state) => {
      state.filters = {
        ...initialFilters,
      };

      state.filtered = state.users;
    })
    .addCase(UserMaintainerTypes.FILTER_USER_TABLE, (state, action) => {
      const filters = {
        ...state.filters,
        ...action.payload,
      };

      state.filters = filters;

      const filtered = state.users.filter((user) => {
        const tempFilters = Object.entries(filters).filter(([, value]) => truthty(value));

        const tempFiltered = tempFilters.some(([key, value]) =>
          user[key].toLowerCase().includes(value.toLowerCase()),
        );

        return tempFilters.length === 0 ? true : tempFiltered;
      });

      state.filtered = filtered;
    })
    .addCase(UserMaintainerTypes.EDIT_USER_MODAL, (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    })
    .addCase(UserMaintainerTypes.EDIT_VERIFY_USER, (state) => {
      const userErrors = {};

      const { password, passwordConfirmation, name, type } = state.user;

      const mainValidations = {
        name: [
          { check: () => truthty(name), message: 'Nombre inválido' },
          { check: () => name.length > 3, message: 'Nombre debe tener más de 3 caracteres' },
        ],
        type: [
          { check: () => truthty(type), message: 'Rol inválido' },
          { check: () => ['user', 'admin'].includes(type), message: 'Rol inválido' },
        ],
      };

      const optionalsValidations = {
        password: [
          { check: () => truthty(password), message: 'Contraseña inválida' },
          { check: () => validatePassword(password).check, message: 'Contraseña inválida' },
        ],
        passwordConfirmation: [
          { check: () => truthty(passwordConfirmation), message: 'Contraseña inválida' },
          {
            check: () => validatePassword(passwordConfirmation).check,
            message: 'Contraseña inválida',
          },
          {
            check: () => passwordConfirmation === password,
            message: 'Contraseñas no coinciden',
          },
        ],
      };

      const validations = password
        ? { ...mainValidations, ...optionalsValidations }
        : mainValidations;

      Object.entries(validations).forEach(([item, validationsList]) => {
        const errors = validationsList
          .filter((validation) => !validation.check())
          .map((validation) => validation.message);

        userErrors[item] = errors[0] || '';
      });

      state.controls.avaibleToSave = Object.values(userErrors).every((item) => !item);

      state.userErrors = {
        ...userErrors,
      };
    })
    .addCase(UserMaintainerTypes.DELETE_USER_SUCCESS, (state, action) => {
      const users = state.users.filter((user) => user.id !== action.payload.user.id);

      state.users = users;
      state.filtered = users;
    })
    .addCase(UserMaintainerTypes.UPDATE_USER_SUCCESS, (state, action) => {
      const users = state.users.map((user) =>
        user.id === action.payload.user.id ? action.payload.user : user,
      );
      state.users = users;
      state.filtered = users;
    })
    .addCase(UserMaintainerTypes.CLOSE_DELETE_CONFIRM_MODAL, (state) => {
      state.controls.deleteConfirmModal = false;
      state.controls.userToDelete = null;
    })
    .addCase(UserMaintainerTypes.OPEN_DELETE_CONFIRM_MODAL, (state, action) => {
      state.controls.deleteConfirmModal = true;
      state.controls.userToDelete = action.payload;
    });
});

export default UserMaintainerReducer;
