import { createAction } from '@reduxjs/toolkit';
import ContactTypes from './contact.types';

export const setControl = createAction(ContactTypes.SET_CONTROL, ({ name, value }) => ({
  payload: { [name]: value },
}));

export const sendEmail = createAction(
  ContactTypes.SEND_EMAIL,
  ({ name, email, phone, message }) => ({
    payload: { name, email, phone, message },
  }),
);

export const sendEmailSuccess = createAction(ContactTypes.SEND_EMAIL_SUCCESS);

export const sendEmailFail = createAction(ContactTypes.SEND_EMAIL_FAIL);

export const validateRecaptcha = createAction(ContactTypes.VALIDATE_RECAPTCHA, ({ token }) => ({
  payload: { token },
}));

export const validateRecaptchaSuccess = createAction(ContactTypes.VALIDATE_RECAPTCHA_SUCCESS);

export const validateRecaptchaFail = createAction(ContactTypes.VALIDATE_RECAPTCHA_FAIL);

const ContactActions = {
  setControl,
  sendEmail,
  sendEmailSuccess,
  sendEmailFail,
};

export default ContactActions;
