export const ADMIN = 'admin';
export const USER = 'user';
export const GUEST = 'guest';
export const ALL = [ADMIN, USER, GUEST];

const roles = Object.seal({
  ADMIN,
  USER,
  GUEST,
  ALL,
});

export const rolesToSelect = [
  { value: 'user', label: 'Usuario' },
  { value: 'admin', label: 'Administrador' },
];

export default roles;
