/* Reducers */
import { CounterReducer } from 'content/features/counter';
import AppReducer from 'content/app.reducer';
import { LoginReducer, RecoveryReducer, RegisterReducer } from 'content/features/authentication';
import { UserTasksReducer } from 'content/features/tasks';
import { UserMaintainerReducer } from 'content/features/user-maintainer';
import { ProfileReducer } from 'content/features/profile';
import ContactReducer from 'content/features/home/contact.reducer';

const MainReducer = {
  app: AppReducer,
  login: LoginReducer,
  counter: CounterReducer,
  register: RegisterReducer,
  recovery: RecoveryReducer,
  userTasks: UserTasksReducer,
  userMaintainer: UserMaintainerReducer,
  profile: ProfileReducer,
  contact: ContactReducer,
};

export default MainReducer;
