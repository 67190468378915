import React, { forwardRef } from 'react';

import { Container, Grid, Link, Typography } from '@mui/material';
import { downloadFile } from 'utils/functions';
import sectionStyles from './section.styles';

const PrivacyStatementSection = forwardRef((props, ref) => (
  <Container sx={sectionStyles.container} ref={ref}>
    <Grid container spacing={{ sm: 8, xs: 4 }}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={sectionStyles.title}>
          Política de Protección de Datos Personales
        </Typography>

        <Typography variant="body1" gutterBottom align="justify">
          Esta declaración de privacidad explica qué información recopilamos sobre usted, para qué
          la usamos y con quién la compartimos. También establece sus derechos en relación con su
          información y cómo puede comunicarse con nosotros para obtener más información o realizar
          consultas. Por favor, lea detenidamente esta declaración antes de utilizar nuestra
          plataforma web. Si desea ver más información sobre la política de protección de datos,
          haga click{' '}
          <Link
            href="/#"
            onClick={(event) =>
              downloadFile(event, '06 Política de Protección de Datos Conciliación Carrier.pdf')
            }>
            aquí
          </Link>
          .
        </Typography>
        {/* <Typography variant="h6" sx={sectionStyles.subtitle}>
          ¿A quién se aplica esta declaración de privacidad y qué cubre?
        </Typography> */}
        {/* <Typography variant="body1" gutterBottom align="justify">
          Esta declaración de privacidad se aplica a la plataforma web, administrada por Deloitte,
          de procesamiento de solicitudes de compensación entre la empresa &ldquo;Asociación
          Regional de Consumidores Adultos Mayores Región del Bío Bío&rdquo;, y la empresa
          &ldquo;Compañía Sud Americana de Vapores S.A.&rdquo; en relación con el proceso de
          Conciliación aprobado por el Tribunal de Defensa de la Libre Competencia,. Esta
          declaración de privacidad establece cómo Deloitte procesará y protegerá la información
          sobre usted, cuando utilice esta plataforma web o al realizar cualquier otra actividad que
          forme parte de la operación del proceso de conciliación.
        </Typography>
        <Typography variant="h6" sx={sectionStyles.subtitle}>
          ¿Qué información recopilamos?
        </Typography>
        <Typography variant="body1" gutterBottom align="justify">
          En el proceso de procesamiento de solicitudes de compensación, recopilaremos los
          siguientes datos personales:
          <br />
          - Nombre y apellidos
          <br />
          - Dirección de correo electrónico
          <br />
          - Número de teléfono
          <br />
          - Información de la empresa &ldquo;Asociación Regional de Consumidores Adultos Mayores
          Región del Bío Bío&rdquo;
          <br />
          - Información de la empresa &ldquo;Compañía Sud Americana de Vapores S.A.&rdquo;
          <br />
          - Detalles de la solicitud de compensación.
          <br />
          Recopilamos esta información para facilitar el proceso de comunicación y negociación entre
          las dos empresas. Solo recopilamos la información necesaria y apropiada para llevar a cabo
          esta actividad.
        </Typography>
        <Typography variant="h6" sx={sectionStyles.subtitle}>
          ¿Cómo usamos la información sobre usted?
        </Typography>
        <Typography variant="body1" gutterBottom align="justify">
          Utilizamos la información recopilada para los siguientes fines:
          <br />
          Procesamiento de solicitudes de compensación: Utilizamos su información para gestionar y
          facilitar el proceso de comunicación y negociación entre la empresa &ldquo;Asociación
          Regional de Consumidores Adultos Mayores Región del Bío Bío&rdquo; y la empresa
          &ldquo;Compañía Sud Americana de Vapores S.A.&rdquo;. Esto incluye compartir su
          información con las partes involucradas en el proceso, como representantes de ambas
          empresas y el personal interno de Deloitte encargado de la plataforma.
          <br />
          Utilizamos sus datos personales en base a los siguientes fundamentos legales:
          <br />
          1. Ejecución de un contrato: El procesamiento de su información personal es necesario para
          cumplir con nuestros compromisos contractuales con la empresa &ldquo;Asociación Regional
          de Consumidores Adultos Mayores Región del Bío Bío&rdquo; y la empresa &ldquo;Compañía Sud
          Americana de Vapores S.A.&rdquo; en relación con la plataforma de procesamiento de
          solicitudes de compensación.
          <br />
          2. Cumplimiento de Bases de Conciliación aprobadas por el Tribunal de Defensa de la Libre
          Competencia.
          <br />
        </Typography>
        <Typography variant="h6" sx={sectionStyles.subtitle}>
          ¿A quién revelamos su información?
        </Typography>
        <Typography variant="body1" gutterBottom align="justify">
          En el contexto del procesamiento de solicitudes de compensación, podemos revelar su
          información personal a las siguientes partes:
          <br />
          1. Empresas &ldquo;Asociación Regional de Consumidores Adultos Mayores Región del Bío
          Bío&rdquo; y &ldquo; Compañía Sud Americana de Vapores S.A.&rdquo;: Compartimos su
          información con las empresas involucradas en el proceso de compensación para facilitar la
          comunicación y negociación entre ellas.
          <br />
          2. Personal interno de Deloitte: Nuestro personal interno encargado de la plataforma de
          procesamiento de solicitudes de compensación tendrá acceso a su información para gestionar
          y facilitar el proceso.
        </Typography>
        <Typography variant="h6" sx={sectionStyles.subtitle}>
          Protección de su información personal
        </Typography>
        <Typography variant="body1" gutterBottom align="justify">
          Tomamos medidas para proteger su información personal y garantizar su seguridad,
          confidencialidad e integridad. Utilizamos medidas físicas, técnicas y organizativas
          apropiadas para proteger sus datos contra accesos no autorizados, divulgación o
          destrucción.
          <br />
          Además, mantenemos estrictas políticas de confidencialidad y restricciones de acceso a su
          información personal dentro de nuestra organización. Solo permitimos el acceso a su
          información a aquellos empleados y contratistas que necesitan conocerla para llevar a cabo
          sus funciones y responsabilidades relacionadas con la plataforma de procesamiento de
          solicitudes de compensación.
        </Typography>
        <Typography variant="h6" sx={sectionStyles.subtitle}>
          ¿Durante cuánto tiempo conservamos su información?
        </Typography>
        <Typography variant="body1" gutterBottom align="justify">
          Mantendremos su información personal durante el tiempo necesario para cumplir con los
          fines establecidos en esta declaración de privacidad, a menos que se requiera o permita un
          período de retención más largo por ley. Una vez cumplido el plazo establecido en el
          acuerdo, eliminaremos la información personal de forma segura.
        </Typography>
        <Typography variant="h6" sx={sectionStyles.subtitle}>
          Sus derechos
        </Typography>
        <Typography variant="body1" gutterBottom align="justify">
          Como titular de datos, usted tiene ciertos derechos en relación con sus datos personales.
          Estos derechos incluyen:
          <br />
          1. Acceso: Usted tiene derecho a solicitar información sobre los datos personales que
          tenemos sobre usted y a obtener una copia de los mismos.
          <br />
          2. Rectificación: Si cree que la información personal que tenemos sobre usted es
          incorrecta o incompleta, tiene derecho a solicitar su corrección o actualización.
          <br />
          3. Eliminación: Usted tiene derecho a solicitar la eliminación de su información personal
          cuando ya no sea necesaria para los fines establecidos en esta declaración de privacidad.
          <br />
          4. Oposición al procesamiento: Usted tiene derecho a oponerse al procesamiento de su
          información personal hasta la fecha límite de solicitudes definida en el Acuerdo de
          Conciliación según corresponda.
          <br />
          Si desea ejercer alguno de estos derechos o tiene alguna consulta sobre el uso de su
          información personal, puede comunicarse con nosotros a través de los datos de contacto
          proporcionados al final de esta declaración de privacidad.
        </Typography>
        <Typography variant="h6" sx={sectionStyles.subtitle}>
          Cambios en esta declaración de privacidad
        </Typography>
        <Typography variant="body1" gutterBottom align="justify">
          Deloitte se reserva el derecho de modificar o enmendar esta declaración de privacidad en
          cualquier momento y a nuestra entera discreción. Cualquier cambio en esta declaración de
          privacidad será efectivo a partir de la fecha de revisión indicada al comienzo de la
          declaración.
          <br />
          Le recomendamos que revise periódicamente esta declaración de privacidad para estar
          informado sobre cómo protegemos su información personal y cómo la utilizamos en nuestra
          plataforma web.
        </Typography> */}
        <Typography variant="h6" sx={sectionStyles.subtitle}>
          Contacto
        </Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={2}>
          Si tiene alguna pregunta, inquietud o solicitud relacionada con esta declaración de
          privacidad o el procesamiento de su información personal, puede comunicarse con nosotros a
          través de los siguientes datos de contacto:
          <br />
          - Deloitte Asesorías Legales y Tributarias Limitada
          <br />
          - Rosario Norte 407, Las Condes, Región Metropolitana
          <br />
          - conciliacioncarrier@deloitte.cl
          <br />- Fono 22729 7000
        </Typography>
        <Typography variant="body1" gutterBottom align="justify" mb={1}>
          Asegúrese de incluir &ldquo;Declaración de Privacidad&rdquo; en el asunto de su
          comunicación para que podamos atender su consulta de manera adecuada.
        </Typography>
        <Typography variant="body1" gutterBottom align="justify">
          Nos comprometemos a responder a todas las consultas y solicitudes en relación con su
          información personal de manera oportuna y de acuerdo con las leyes y regulaciones
          aplicables.
        </Typography>
        <Typography variant="body1" gutterBottom align="justify">
          Para mayor información, descargue el anexo de declaración de privacidad{' '}
          <Link
            href="/documents/06%20Pol%C3%ADtica%20de%20Protecci%C3%B3n%20de%20Datos%20Conciliaci%C3%B3n%20Carrier.pdf"
            download="protección-datos-personales.pdf">
            aquí
          </Link>
        </Typography>
      </Grid>
    </Grid>
  </Container>
));

export default PrivacyStatementSection;
