import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/* Material UI */
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardMedia,
  Paper,
} from '@mui/material';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Project */
import Paths from 'utils/paths';

/* Public */
// import TCITLogo from '/assets/images/TcitLogo_123x50.png';
const TCITLogo = `${window.location.origin}/images/TcitLogo_123x50.png`;

function TemporaryDrawer(props) {
  const appState = useSelector((state) => state.app);
  const userRole = appState.user?.type || 'guest';

  const { side, openByDefault, setOpenDrawer } = props;

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();

  const tabValidation = (event) => event.key === 'Tab' || event.key === 'Shift';
  const toggleValidations = (event) => event.type === 'keydown' && tabValidation(event);

  const toggleDrawer = (anchor, open) => (event) => {
    if (toggleValidations(event)) return;

    setState({ ...state, [anchor]: open });
  };

  const goToHome = () => {
    navigate('/');
    setOpenDrawer(false);
  };

  const handleRouteClick = (path) => {
    navigate(path);
    setOpenDrawer(false);
  };

  const { publics, shared, protecteds } = Paths;

  const views = [...shared, ...publics, ...protecteds]
    .filter((path) => path.name)
    .filter((path) => path.allowedRoles.includes(userRole))
    .map(({ Icon, path, name }) => (
      <ListItem key={path} disablePadding>
        <ListItemButton onClick={() => handleRouteClick(path)}>
          <ListItemIcon>{Icon ? <Icon /> : null}</ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>
    ));

  const list = (anchor) => (
    <Box
      sx={{ width: ['top', 'bottom'].includes(anchor) ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <Paper sx={{ height: '10vh' }} elevation={2} square>
        <CardMedia
          onClick={() => goToHome()}
          image={TCITLogo}
          sx={{
            width: '100%',
            // cursor: 'pointer',
            height: '100%',
            backgroundSize: 'auto',
          }}
        />
      </Paper>
      <Divider />
      <List>{views}</List>
    </Box>
  );

  return (
    <Drawer
      anchor={side}
      open={openByDefault}
      onClose={() => setOpenDrawer(false)}
      test-dataid="app-drawer">
      {list(side)}
    </Drawer>
  );
}

TemporaryDrawer.propTypes = {
  setOpenDrawer: PropTypes.func.isRequired,
  side: PropTypes.string,
  openByDefault: PropTypes.bool,
};

TemporaryDrawer.defaultProps = {
  side: 'left',
  openByDefault: false,
};

export default TemporaryDrawer;
