import { spawn, takeLatest, put } from 'redux-saga/effects';

import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import {
  sendEmail,
  sendEmailSuccess,
  sendEmailFail,
  validateRecaptcha,
  validateRecaptchaSuccess,
  validateRecaptchaFail,
} from './contact.actions';

function* validateCaptcha() {
  yield takeLatest(validateRecaptcha, function* validateReCaptcha(action) {
    try {
      const { token } = action.payload;
      const body = JSON.stringify({ token });

      const response = yield apiRequest('api/v1/recaptcha', { method: 'post', body });

      if (response.error) {
        yield put(validateRecaptchaFail());
      } else {
        yield put(validateRecaptchaSuccess(response));
      }
    } catch (e) {
      yield put(validateRecaptchaFail());
    }
  });
}

function* sendContactEmail() {
  yield takeLatest(sendEmail, function* sendContactUsEmail(action) {
    try {
      const { name, email, phone, message } = action.payload;
      const body = JSON.stringify({ name, email, phone, message });

      const response = yield apiRequest('api/v1/mailer/sendContactUs', { method: 'post', body });

      if (response.error) {
        throw new Error(response.message);
      } else {
        defaultSuccessToast({ message: 'Mensaje enviado.' });
        yield put(sendEmailSuccess());
      }
    } catch (e) {
      defaultErrorToast({ message: e.message });
      yield put(sendEmailFail());
    }
  });
}

export default function* ContactSaga() {
  yield spawn(sendContactEmail);
  yield spawn(validateCaptcha);
}
