import { takeLatest, spawn, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

/* Project */
import apiRequest from 'utils/api';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultInfoToast from 'utils/toastify/defaultInfoToast';

import { updateAvatar as up, updateAvatarSuccess, updateAvatarFail } from './profile.actions';

function* updateAvatarSaga() {
  yield takeLatest(up, function* updateAvatar(action) {
    const { user, file } = action.payload;

    defaultInfoToast({ message: 'Actualizando Avatar...' });

    const { url } = yield apiRequest(`api/v1/users/${user.id}/get_presigned_url`, {
      method: 'POST',
      body: JSON.stringify({
        fileName: file.name,
        fileType: file.type,
      }),
    });

    yield fetch(decodeURI(url), {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
      },
      body: file,
    });

    const response = yield apiRequest(`api/v1/users/${user.id}/avatar`);

    toast.dismiss();

    if (!response.error) {
      defaultSuccessToast({ message: 'Avatar actualizado correctamente.' });
      yield put(
        updateAvatarSuccess({
          ...user,
          avatarUrl: response.avatarUrl,
        }),
      );
    } else {
      defaultErrorToast({ message: 'Falló la actulizacion del Avatar.' });
      yield put(updateAvatarFail());
    }
  });
}

export default function* ProfileSaga() {
  yield spawn(updateAvatarSaga);
}
