const sectionStyles = {
  container: {
    paddingTop: 12.5,
    paddingBottom: 3.5,
  },

  title: {
    color: '#4f5356c9',
    fontWeight: 'bold',
  },

  subtitle: {
    color: '#85bc24',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },

  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    width: { md: '49%', xs: '100%' },
    color: '#66b2ff',
    fontWeight: 'bold',
  },

  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: '3px solid #86BC25',
    color: '#86BC25',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 2,
  },
};

export default sectionStyles;
