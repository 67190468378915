import { createAction } from '@reduxjs/toolkit';

import ProfileTypes from './profile.types';

export const updateAvatar = createAction(ProfileTypes.UPDATE_AVATAR);

export const updateAvatarSuccess = createAction(ProfileTypes.UPDATE_AVATAR_SUCCESS);

export const updateAvatarFail = createAction(ProfileTypes.UPDATE_AVATAR_FAIL);

const ProfileActions = {
  updateAvatar,
  updateAvatarSuccess,
  updateAvatarFail,
};

export default ProfileActions;
